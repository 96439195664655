<template>
  <PointBase :loader_prop="loader_prop">
    <div class="receving_packages_banner">
      <div class="comman_banner">
        <h2>קבלת חבילה חדשה</h2>
      </div>
      <div class="container">
        <div class="singup_page">
          <form @submit="to_box" class="singup_form">
            <p class="error_text" style="display: none">אופס, שכחת משהו</p>
            <p class="error_text">{{ errs }}</p>
            <div class="form-group">
              <label>מספר חבילה</label>
              <input
                type="text"
                class="form-control"
                v-model="package_number"
              />
            </div>
            <div class="form-group">
              <!-- <label>מספר הטלפון של מקבל החבילה</label> -->
              <!-- <select name="" id="" class="form-control" v-model="phone_number">
                <option v-for="phone in phones" :value="phone.phone_number" :key="phone.id">{{phone.phone_number}} -  {{phone.name}}</option>
              </select> -->
              <label>מספר הטלפון של מקבל החבילה</label>
              <v-select  id="mySelect" :options="phones" :value="phones.phone_number" label="multi" v-model="phone_number"
              

              
              ></v-select>
            </div>
            <div class="form-group">
              <label>בחירת גודל תיבה</label>
              <select name=""  class="form-control" v-model="size">
                <option value="j">ג'מבו (90x90)</option>
                <option value="l">גדול (11.5x40)</option>
                <option value="m">בינוני (24.5x40)</option>
                <option value="s">קטן (45x40)</option>
              </select>
            </div>
            <input type="submit" class="comman_anchor" value="הוספה" />
          </form>
        </div>
        <span class="warning_message"
          >שימו לב! עם לחיצה על כפתור “הוספה“ תישלח הודעה מיידית ללקוח</span
        >
      </div>
    </div>
  </PointBase>
</template>

<script>
import BackendModel from "../../models/BackendModel";
import PointBase from "./PointBase";
import vSelect from "vue-select";

import("./point/css/style.css");
export default {
  name: "PointNewPackage",
  components: {
    PointBase,
    vSelect,
  },
  data() {
    return {
      package_number: "",
      phone_number: "",
      size: "",
      errs: "",
      loader_prop: true,
      phones: [],
    };
  },
  //
  async mounted() {
    let backendModel = new BackendModel();
    let phones = await backendModel.point_Request(
      "/Api/service/backoffice/point_get_phones",
      {}
    );
    this.phones = phones.data.phones;
    this.loader_prop = false;
    console.log(this.phones);
    this.errs = "";
  },

  methods: {

    updatephone(phones, phone_number) {
      phones.phone_number = phone_number
    },

    async to_box(e) {
      e.preventDefault();
      console.log(this.phone_number.split(" -")[0])
      const backendModel = new BackendModel();
      let res = await backendModel.point_Request(
        "/Api/service/backoffice/point_find_box",
        {
          package_number: this.package_number,
          phone_number: this.phone_number.split(" -")[0],
          size: this.size,
        }
      );
      if (res.data.err == "less_data") {
        this.errs = "אופס, שכחת משהו";
      }
      if (res.data.err == "user_does_not_exist") {
        this.errs = "המשתמש לא קיים במערכת";
      }
      if (res.data.err == "NOT_FREE_BOX") {
        this.errs = "אין תיבות פנויות";
      }
      if (res.data.res == "NOK" && res.data.err == null) {
        this.errs = "שגיאה";
      }
      if (res.data.res == "OK") {
        let box_id = res.data.view_data.id;
        let box_number = res.data.view_data.number;
        await this.$router.push({
          path: "/point/point_package_validation",
          query: { box_id, box_number },
        });
        this.loader_prop = false;
      }
    },
  },
};
</script>

<style>
@import "point/css/style.css";
</style>

